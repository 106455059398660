import React from 'react';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link } from 'react-router-dom';


//Стили
import '../css/templatetask.css';

//Языки / переводы
import translationStore from '../locales/locales';


const TemplateTask = observer(({ task, click, modalBybit }) => {
    //Языки / переводы
    const tr = translationStore.trnsl;

    const openModalByBit = () => {
        modalBybit();
        console.log('Клик по шестерни!');
    }

    return (
        <>
            <div className='task_item'>
                <div className={task.color == 'blue' ? 'task_itemwrap' : task.color == 'red' ? 'task_itemwrapred' : task.color == 'green' ? 'task_itemwrapgreen' : task.color == 'yellow' ? 'task_itemwrapyellow' : 'task_itemwrap'}>
                    <div className='task_itemwrap_left' onClick={() => click(task)}>
                        <h3 className='task_itemwrap_title'>{tr.user_lang == 'ru' ? task.title : tr.user_lang == 'en' ? task.title_en : task.title_es}</h3>
                        <div className='task_itemwrap_simpletext_flexrow'>
                            {task.awardspins != '0' ? (
                                <div className='task_itemwrap_simpletext'>+  {task.awardspins} scrolls</div>
                            ) : null}
                            {task.awardmonets != '0' ? (
                                <div className='task_itemwrap_simpletext'>+ {task.awardmonets} <img src="/images/moneta.png" style={{ width: '22px', height: '18px', verticalAlign: 'sub' }} /></div>
                            ) : null}
                            {task.completed_by_current_user === true || task.bybit_link !== null ? (
                                <div className='task_itemwrap_check'><img src="/images/check.png" style={{ width: '30px', height: '30px', verticalAlign: 'sub' }} /></div>
                            ) : null}
                        </div>
                    </div>
                    <div className='task_itemwrap_right'>
                        <img src={task.image} alt={task.title} style={{ width: '75px', height: 'auto' }} />
                        {task.type == 'form' && task.completed_by_current_user === true || task.bybit_link !== null ? (
                            <div className='task_gear_wheel' onClick={() => openModalByBit(true)}>
                                <img src='../images/g.png' style={{ width: '30px', height: '30px' }} />
                            </div>
                        ) : null}
                    </div>

                </div>
            </div>
        </>
    );
});

export default TemplateTask;
