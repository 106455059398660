import React, { Children, useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import axios from 'axios';
import { Routes, Route, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

//Стили
import '../css/modaluni.css';

//Языки / переводы
import translationStore from '../locales/locales';

//Компоненты
import NftTimer from '../components/NftTimer';

//Сторы
import PlayerStore from '../store/playerStore';
import nftStore from '../store/nftStore';

//Конфиг
import config from '../config';

//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UniModals = observer(({ open, children, onOpenChange }) => {

    //Языки / переводы
    const tr = translationStore.trnsl;

    const handleCloseModal = () => {
        onOpenChange(false); //Запускаем калбек фенкцию с параметром false чтобы зактыть модальное окно
    }


    return (
        <div className='modal_wrap'>
            <Modal
                show={open}
                onHide={handleCloseModal} centered>
                <div className='modalContent'>
                    <button className='buttonClose' onClick={handleCloseModal}>
                        <img src='images/close.png' />
                    </button>

                    <div className='modal_uni'>
                        {children}
                    </div>
                </div>
            </Modal>

        </div>
    );
});

export default UniModals;
