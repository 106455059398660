import { makeAutoObservable } from "mobx";
import axios from 'axios';

//Конфиг
import config from '../config';

class PlayerStore {

    player = {
        idchat: null,
        scrolling: 0,
        timezone: '',
        reels: [
            {
                imageSrc: '/images/reel-strip1.png',
                symbols: [
                    { title: 'pepe', position: 100, weight: 8 },
                    { title: 'ton', position: 300, weight: 1 },
                    { title: 'graf', position: 500, weight: 3 },
                    { title: 'doge', position: 700, weight: 4 },
                    { title: 'pira', position: 900, weight: 5 },
                    { title: 'eth', position: 1100, weight: 6 },
                    { title: 'btc', position: 1300, weight: 5 },
                    { title: 'not', position: 1500, weight: 7 },
                    { title: 'rkt', position: 1700, weight: 2 },
                ]
            },
            {
                imageSrc: '/images/reel-strip2.png',
                symbols: [
                    { title: 'doge', position: 100, weight: 4 },
                    { title: 'pira', position: 300, weight: 5 },
                    { title: 'eth', position: 500, weight: 6 },
                    { title: 'btc', position: 700, weight: 5 },
                    { title: 'not', position: 900, weight: 7 },
                    { title: 'rkt', position: 1100, weight: 2 },
                    { title: 'pepe', position: 1300, weight: 8 },
                    { title: 'graf', position: 1500, weight: 3 },
                    { title: 'ton', position: 1700, weight: 1 },
                ]
            },
            {
                imageSrc: '/images/reel-strip3.png',
                symbols: [
                    { title: 'eth', position: 100, weight: 6 },
                    { title: 'btc', position: 300, weight: 5 },
                    { title: 'not', position: 500, weight: 7 },
                    { title: 'rkt', position: 700, weight: 2 },
                    { title: 'pepe', position: 900, weight: 8 },
                    { title: 'graf', position: 1100, weight: 3 },
                    { title: 'ton', position: 1300, weight: 1 },
                    { title: 'doge', position: 1500, weight: 4 },
                    { title: 'pira', position: 1700, weight: 5 }
                ]
            }
        ],
        tguser: null
    }



    //Делаем отслеживаемость
    constructor() {
        makeAutoObservable(this)
    }

    //Добавляем
    editPlayerStore(attr, value) {
        this.player[attr] = value;
    }

    //Метод для полной замены объекта player
    setPlayer(newPlayerData) {
        this.player = { ...newPlayerData };
    }

    //Метод для обновления вложенных параметров
    updatePlayerParameter(path, value) {
        const keys = path.split('.');  // Разбиваем путь в строке на массив
        let obj = this.player;

        // Проходим по объекту, чтобы найти необходимое вложенное свойство
        for (let i = 0; i < keys.length - 1; i++) {
            obj = obj[keys[i]];
        }

        // Обновляем значение последнего ключа
        obj[keys[keys.length - 1]] = value;
    }

    //Запрос для обновления ползователя
    postUpdatePlayerParameter() {
        const apiUrl = config.apiBaseUrl + '/api/api/v1/login';

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        // Получаем idchat (проверяем, что оно существует)
        const idchat = this.player?.idchat;

        if (!idchat) {
            console.error("Ошибка: idchat не найден");
            return;
        }

        // Отправка POST-запроса
        axios.post(apiUrl, { idchat }, { headers })
            .then(response => {
                console.log("ПОЛЬЗОВАТЕЛЬ playerStore: ", JSON.stringify(response.data, null, 1));
                this.setPlayer(response.data.user); // Обновляем состояние пользователя
            })
            .catch(error => {
                console.error("ПОЛЬЗОВАТЕЛЬ ERROR:", error.response ? error.response.data : error.message);
            });
    }

}

const playerStore = new PlayerStore();
export default playerStore; 