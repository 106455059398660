import React from 'react';
import { observer } from "mobx-react-lite";

// Стили
import '../css/templatereferal.css';

// Языки / переводы
import translationStore from '../locales/locales';

const TemplateReferalLine = observer(({ referal, lineNumber }) => {
    const trnsl = translationStore.trnsl;

    // Обработчик для замены изображения на дефолтное
    const handleImageError = (event) => {
        event.target.src = '/images/ava_default.png';
    };

    // Рекурсивная функция для получения рефералов на указанной линии
    const getReferalsAtLine = (currentReferals, currentDepth) => {
        if (!Array.isArray(currentReferals) || currentReferals.length === 0) {
            return [];
        }

        // Если достигли нужного уровня, возвращаем текущий массив рефералов
        if (currentDepth === lineNumber) {
            return currentReferals;
        }

        // Собираем всех рефералов из дочерних узлов в один плоский массив
        return currentReferals.reduce((acc, ref) => {
            // Проверяем, что для каждого реферала есть дочерние элементы (если они есть) и продолжаем рекурсию
            const childReferals = getReferalsAtLine(ref.referals || [], currentDepth + 1);
            return acc.concat(childReferals);
        }, []);
    };

    // Получаем рефералов для указанной линии
    const referalsAtLine = getReferalsAtLine([referal], 1);


    return (
        <>
            {referalsAtLine.map((ref, index) => (
                <div key={ref.id || `ref-${index}`} className="referal_item">
                    <div className="referal_item_row">
                        <div className="referal_item_image">
                            <img
                                src={ref?.user_photo || '/images/ava_default.png'}
                                className="referal_user_avatar"
                                onError={handleImageError}
                                alt="User Avatar"
                            />
                        </div>
                        <div className="referal_item_name">
                            <div>
                                <div className="referal_item_name_user">
                                    {ref?.username || "no name"}
                                </div>
                            </div>
                        </div>
                        <div className="referal_item_rew">
                            <div className="referal_item_rewmonets">
                                {ref?.balance} <img src="/images/moneta.png" className="referal_item_icon_monets" alt="Moneta Icon" />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
});

export default TemplateReferalLine;
