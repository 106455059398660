import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { observer } from "mobx-react-lite";
import { Routes, Route, Link, useNavigate } from 'react-router-dom';

//Конфиг
import config from '../config';

//Стили
import '../css/invest.css';

//Языки / переводы / сторы
import translationStore from '../locales/locales';
import PlayerStore from '../store/playerStore';
import nftStore from '../store/nftStore'; //Стор и NFT продуктами


//Компоненты
import TopHeader from '../components/TopHeader';
import NftModals from '../components/NftModals';
import NftTimer from '../components/NftTimer';
import UniModals from '../components/UniModals';


//Toast сообщения
import { ToastContainer, toast, Bounce, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Invest = observer(() => {

    //Языки / переводы
    const tr = translationStore.trnsl;


    // Инициализация хука useNavigate для навигации
    const navigate = useNavigate();

    //Кнопки горизонтального меню
    const menu = [
        { "text": "All", "idmenu": 1, "category": 0 },
        { "text": "NFT 20%", "idmenu": 3, "category": 1 },
        { "text": "NFT 50%", "idmenu": 4, "category": 2 },
        { "text": "NFT 100%", "idmenu": 5, "category": 3 },
    ];

    //Для фильтра 
    const [filterMenu, setFilterMenu] = useState(0);
    const hangleFilterMenu = (category) => {
        setFilterMenu(category);
    }

    const hangleMyNft = (category) => {
        setFilterMenu(100);
    }

    //Открыть / закрыть модально окно
    const [openModal, setOpenModal] = useState(false); //Для открытия окна подарка gift
    const [modalNft, setModalNft] = useState(null); //Даныне для отобраения в NftModals компоненте

    //Открываем / закрываем модельное окно NftModals
    const handleOpenModal = (value) => {//Калбек функция для откртытия или закрытия модального окна NftModals
        setOpenModal(value);
    };

    //Записываем данные в стейт для отображения в модальном окне NftModals
    const enrollState = (item) => {
        setModalNft(item);
        console.log('NFT--> : ', JSON.stringify(item, null, 1));
    }

    //Функция запроса NFT с сервера
    const nftList = () => {
        const apiUrl = config.apiBaseUrl + '/api/api/v1/nfts';
        const token = PlayerStore.player.token; // Получаем токен

        // Заголовки запроса
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Добавляем токен в заголовок Authorization
        };
        //Отправляемые даныне
        const dataRec = {

        };

        axios.get(apiUrl, { headers })
            .then(response => {
                console.log('СПИСОК NFT: ', JSON.stringify(response.data, null, 1));
                if (response?.data) {
                    nftStore.editNftStore('shop', response?.data);
                }
            })
            .catch(error => {
                if (error.response) {
                    // Сервер ответил статусом, который выходит за пределы диапазона 2xx
                    console.error('Ошибка 2xx:', error.response.data);
                } else if (error.request) {
                    // Запрос был сделан, но ответ не был получен
                    console.error('Ошибка запроса:', error.request);
                } else {
                    // Ошибка при настройке запроса
                    console.error('Ошибка:', error.message);
                }
            });
    }


    useEffect(() => {
        if (PlayerStore?.player?.token) {
            nftList();
            nftStore.myNftList();
            nftStore.checkTaskInvest();
        }
    }, []);


    //Для модального окна успешный или не успешный доход
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [openModalError, setOpenModalError] = useState(false);
    //Открываем / закрываем модельное окно UniModals УДАЧНО
    const handleOpenUniModalSuccess = (value) => {//Калбек функция для откртытия или закрытия модального окна UniModals
        setOpenModalSuccess(value);
    };
    //Открываем / закрываем модельное окно UniModals НЕУДАЧНО
    const handleOpenUniModalError = (value) => {//Калбек функция для откртытия или закрытия модального окна UniModals
        setOpenModalError(value);
    };

    return (
        <>
            <TopHeader />
            <div className="menuContainer" style={{ scrollbarWidth: "none", msOverflowStyle: "none", overflowX: "auto", }}>
                <button
                    onClick={() => hangleMyNft(100)}
                    className={filterMenu == 100 ? "menuItemActive" : "menuItem"}
                >
                    My NFT
                </button>
                {menu.map((item) => (
                    <div key={item.idmenu}>
                        <button
                            onClick={() => hangleFilterMenu(item.category)}
                            className={filterMenu == item.category ? "menuItemActive" : "menuItem"}
                        >
                            {item.text}
                        </button>
                    </div>
                ))}
            </div>


            <div className="nftContainer">
                <div className='myNft'>

                    {nftStore?.nft?.myNft?.map((mynftItem, ind) => (
                        <>
                            {filterMenu == 100 ? (
                                <div key={'ind' + ind} className="nftCol">
                                    <button
                                        className="nftItemButton"
                                        onClick={
                                            //() => updateNftStore(index, 100) //Обновить стор после покупки NFT (перезаписать category 100)
                                            () => {
                                                enrollState(mynftItem)
                                                handleOpenModal(true)
                                            }
                                        }>
                                        <div className="nftItem">
                                            <img className="nftImage" src={`/nft/nft${mynftItem.nft_data.id}.png`} alt="" />
                                            {mynftItem?.nft_timer ? (
                                                <div className='nftTimerWrap'>
                                                    <div className='nftTimer'>
                                                        <NftTimer targetDate={PlayerStore?.player?.nft_timer} />
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div className='nftTextBlok'>
                                                <div className='nftTitle'>
                                                    {mynftItem?.nft_data[`text${tr.user_lang}`]}
                                                </div>
                                                <div className='nftSub'>
                                                    <div className='nftPrice'>
                                                        {mynftItem.nft_data.price} USDT
                                                    </div>
                                                    <div className='nftProcent'>
                                                        {mynftItem.nft_data.procent * 2}%
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='nftMy'>
                                                <img className="nftMyImage" src="/images/nftMy.svg" alt="" />
                                            </div>

                                        </div>
                                    </button>
                                </div>
                            ) : null}
                        </>
                    ))}
                </div>

                {nftStore?.nft?.shop?.map((nftItem, index) => (
                    <>
                        {filterMenu == 0 || filterMenu == nftItem.category ? (
                            <div key={nftItem.idmenu} className="nftCol">
                                <button
                                    className={nftItem?.active == 0 ? "nftItemButton grayscale" : "nftItemButton"}
                                    onClick={
                                        //() => updateNftStore(index, 100) //Обновить стор после покупки NFT (перезаписать category 100)
                                        () => {
                                            enrollState(nftItem)
                                            handleOpenModal(true)
                                        }
                                    }>
                                    <div className="nftItem">
                                        <img className="nftImage" src={`/nft/nft${nftItem.id}.png`} alt="" />
                                        <div className='nftTextBlok'>
                                            <div className='nftTitle'>
                                                {nftItem[`text${tr.user_lang}`]}
                                            </div>
                                            <div className='nftSub'>
                                                <div className='nftPrice'>
                                                    {nftItem.price} USDT
                                                </div>
                                                <div className='nftProcent'>
                                                    {nftItem.procent * 2}%
                                                </div>
                                            </div>
                                        </div>
                                        {nftItem?.category == 100 ? (
                                            <div className='nftMy'>
                                                <img className="nftMyImage" src="/images/nftMy.svg" alt="" />
                                            </div>
                                        ) : null}

                                    </div>
                                </button>
                                {nftItem?.active == 0 ? (
                                    <div className='nftSoon'>
                                        soon
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </>
                ))}
            </div>

            {modalNft ? (
                <NftModals
                    open={openModal}
                    onOpenChange={handleOpenModal}
                    data={modalNft}
                    nftModalSuccess={handleOpenUniModalSuccess}
                    nftModalError={handleOpenUniModalError}
                />
            ) : null}


            <UniModals open={openModalSuccess} onOpenChange={handleOpenUniModalSuccess}>
                <div className="vbk_image_wrap">
                    <img className="vbk_image" src="/images/vbk_yes.png" alt="" />
                </div>

                <div className="vbk_text_modal">
                    {tr[tr.user_lang].vbr_reward_success}

                </div>
            </UniModals>

            <UniModals open={openModalError} onOpenChange={handleOpenUniModalError}>
                <div className="vbk_image_wrap">
                    <img className="vbk_image" src="/images/vbk_no.png" alt="" />
                </div>
                <div className="vbk_text_modal">
                    {tr[tr.user_lang].vbr_reward_error}
                </div>
            </UniModals>

            <ToastContainer />
        </>
    );
});


export default Invest;
